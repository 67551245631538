.misCards{
    border-radius: 10px !important;
}

label {
    font-weight: bold !important;
}

.ant-modal-title {
    font-weight: bold !important;
}
.logo {
  width: 160px;
  margin: 0px 0px 0px 25px;
}

.myHeader {
 background: #f8f9fa !important;
 border: 1px solid #dee2e6;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

/* titulos de las tarjetas */
.ant-card-head-title {
  font-weight: bold;
}
.ant-table-column-title {
  font-weight: bold;
}

/* mis fuentes */
.myfont{
  font-weight: bold !important;
}

/* logout */
.btnLogout{
  display: inline;
}

/* round */
input[type="text"], 
input[type="number"], 
input[type="email"], 
input[type="password"], 
button, 
input[type="datetime-local"],
input[type="date"] {
  border-radius: 10px !important;
}

textarea{
  border-radius: 10px !important;
}

.ant-select-selector{
  border-radius: 10px !important;
}

.ant-picker{
  border-radius: 10px !important;
}

.ant-input-affix-wrapper{
  border-radius: 10px !important;
}

/* modales */
.ant-modal-content {
  overflow: auto !important;
  border-radius: 10px !important
}

/* myDrawer */
.ant-drawer-wrapper-body{
  background-color: #001529 !important;
}

.ant-drawer-header{
  height: 100px;
}

/* tarejetas niveles */
.cardNivel{
  box-shadow: 0px 8px 20px #00000033 !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important; 
}

.colNivel{
  margin: 30px 15px 15px 15px !important;
  width: 100% ;
  max-width: 370px !important;
}

.ant-card-meta-description, .ant-card-meta-title{
  color: white !important;
}

.imgCard{
  height: 100% !important;
  width: 100% !important;
  object-fit: cover !important;
  object-position: top;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;

  padding: 1px !important;
}

.cardNivel > .ant-card-cover{
  height: 320px !important;
}

.cardNivel > .ant-card-body{
  background-color: black !important;
  height: 150px;
}

/* estilos para los videos */
.containerVideo{
  margin-top: 30px;
}

.MuiBox-root{
  padding: 0px !important;
}

.myToolbar{
  margin-bottom: 5px;
}

table td, table th {
  border: 1px solid #ddd;
}

/* modal agregar nivles al usuario */
.modalNiveles .ant-modal-body{
  padding-top: 0px;
}